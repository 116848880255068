@font-face
{
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/montserrat--regular.otf");
}

@font-face
{
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/montserrat--medium.otf");
}

@font-face
{
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/montserrat--bold.otf");
}