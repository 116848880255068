@import "assets/styles/placeholders.scss";

.header
{
    z-index: 1;
    width: 100%;

    @include media("medium")
    {
        display: block;
        flex-direction: row;
        width: 720px;
        height: auto;
        margin-right: auto;
        margin-left: auto;
    }

    @include media("large")
    {
        width: 960px;
    }

    @include media("very-large")
    {
        width: 100%;
        max-width: 1410px;
    }

    &__bar
    {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 20px;
        background-color: $color--white;

        @include media("medium")
        {
            padding: 34px;
        }

        .bar
        {
            &__logo
            {
                height: 60px;

                @include media("medium")
                {
                    height: 90px;
                }
            }

            &__icon
            {
                @include media("medium")
                {
                    display: none;
                }
            }
        }

        #hamburger
        {
            cursor: pointer;

            @include media("medium")
            {
                display: none;
            }
        }

        #cross
        {
            display: none;
            cursor: pointer;
        }
    }

    &__navigation
    {
        position: relative;
        display: flex;
        align-items: center;
        padding-top: 80px;
        padding-bottom: 80px;
        background-position: left;
        background-repeat: no-repeat;
        background-image: url("../../assets/graphics/images/background--blue.jpg");
        background-size: cover;

        @include media("medium")
        {
            display: block;
            flex-grow: 0;
            align-items: stretch;
            padding-top: 0;
            padding-bottom: 0;
        }

        &::before
        {
            content: "";
            position: absolute;
            left: 48px;
            display: none;
            width: 1px;
            height: 100%;
            opacity: 0.2;
            background-color: $color--white;

            @include media("medium")
            {
                left: 0;
                top: 106px;
                display: block;
                width: 100%;
                height: 1px;
            }
        }

        .navigation
        {
            &__button
            {
                width: 180px;
                padding-top: 16px;
                padding-bottom: 16px;
                margin-right: auto;
                margin-left: auto;
                background-color: $color--trinidad;
                color: $color--white;
                font-weight: 500;
                font-size: 1rem;
                line-height: 1rem;
                text-align: center;

                @include media("medium")
                {
                    transform: translateY(-50%);
                    font-size: 1.25rem;
                    line-height: 1.25rem;
                }
            }

            &__list
            {
                display: none;
                box-sizing: border-box;
                width: 100%;
                padding-right: 42px;
                padding-left: 42px;

                @include media("medium")
                {
                    display: flex;
                    justify-content: center;
                    padding-right: 0;
                    padding-left: 0;
                    padding-top: 48px;
                    padding-bottom: 66px;
                }
            }

            &__item
            {
                display: flex;
                align-items: center;
                color: $color--white;
                font-weight: 500;
                font-size: 1.25rem;
                line-height: 1.25rem;

                @include media("medium")
                {
                    flex-direction: column;
                    font-weight: 400;
                }

                &:not(:last-of-type)
                {
                    margin-bottom: 60px;

                    @include media("medium")
                    {
                        margin-right: 56px;
                        margin-bottom: 0;
                    }
                }

                &::before
                {
                    content: "";
                    display: block;
                    width: 13px;
                    height: 13px;
                    border-radius: 50%;
                    margin-right: 30px;
                    background-color: $color--white;

                    @include media("medium")
                    {
                        margin-right: 0;
                        margin-bottom: 14px;
                    }
                }
            }
        }
    }
}

.active
{
    display: flex;
    flex-direction: column;
    height: 100vh;

    .header
    {
        &__bar
        {
            #hamburger
            {
                display: none;
            }

            #cross
            {
                display: block;
            }
        }

        &__navigation
        {
            flex-grow: 1;

            &::before
            {
                display: block;
            }

            .navigation
            {
                &__button
                {
                    display: none;
                }

                &__list
                {
                    display: block;
                }
            }
        }
    }
}

.main
{
    overflow: hidden;
    
    &__about
    {
        @extend %container;

        position: relative;
        margin-bottom: 50px;

        @include media("medium")
        {
            margin-bottom: 0;
        }

        .about
        {
            &__images
            {
                position: relative;
                height: 320px;
                margin-top: 40px;
                margin-bottom: 30px;

                @include media("small")
                {
                    height: 480px;
                }

                @include media("medium")
                {
                    height: 726px;
                    margin-top: 80px;
                    margin-bottom: 170px;
                }
            }

            &__image
            {
                position: absolute;
                left: 50%;
                top: 50%;

                &--logo
                {
                    @extend .about__image;

                    transform: translate(-50%, -50%);
                    width: 40%;

                    @include media("medium")
                    {
                        width: auto;
                    }
                }

                &--internal
                {
                    @extend .about__image;

                    left: 50%;
                    top: 50%;
                    width: 75%;

                    @include media("very-small")
                    {
                        left: calc(50% - 120px);
                        top: calc(50% - 120px);
                    }

                    @include media("small")
                    {
                        left: calc(50% - 180px);
                        top: calc(50% - 180px);
                    }

                    @include media("medium")
                    {
                        left: calc(50% - 271px);
                        top: calc(50% - 271px);
                        width: auto;
                    }
                }

                &--external
                {
                    @extend .about__image;

                    left: 50%;
                    top: 50%;
                    width: 100%;

                    @include media("very-small")
                    {
                        left: calc(50% - 160px);
                        top: calc(50% - 160px);
                    }

                    @include media("small")
                    {
                        left: calc(50% - 240px);
                        top: calc(50% - 240px);
                    }

                    @include media("medium")
                    {
                        left: calc(50% - 363px);
                        top: calc(50% - 363px);
                        width: auto;
                    }
                }
            }

            &__information
            {
                position: relative;
                padding-left: 28px;

                @include media("medium")
                {
                    position: absolute;
                    width: 300px;
                    padding-left: 0;
                }

                &:not(:last-of-type)
                {
                    margin-bottom: 10px;

                    @include media("medium")
                    {
                        margin-bottom: 0;
                    }
                }

                &::before
                {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 12px;
                    display: block;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: $color--science-blue;
                }

                &--first
                {
                    @extend .about__information;

                    @include media("medium")
                    {
                        left: 0;
                        top: 40px;
                    }

                    @include media("large")
                    {
                        left: -10px;
                        top: 160px;
                    }

                    @include media("very-large")
                    {
                        left: 60px;
                    }

                    &::before
                    {
                        @include media("medium")
                        {
                            left: 249px;
                            top: -30px;
                        }

                        @include media("large")
                        {
                            left: 200px;
                        }

                        @include media("very-large")
                        {
                            left: 249px;
                        }
                    }
                }

                &--second
                {
                    @extend .about__information;

                    @include media("medium")
                    {
                        left: 430px;
                        top: 500px;
                    }

                    @include media("large")
                    {
                        left: 680px;
                        top: 360px;
                    }

                    @include media("very-large")
                    {
                        left: 900px;
                    }

                    &::before
                    {
                        @include media("medium")
                        {
                            left: 153px;
                            top: -18px;
                        }

                        @include media("large")
                        {
                            left: 56px;
                        }

                        @include media("very-large")
                        {
                            left: -44px;
                        }
                    }
                }

                &--third
                {
                    @extend .about__information;

                    @include media("medium")
                    {
                        left: 0;
                        top: 630px;
                    }

                    @include media("large")
                    {
                        left: 80px;
                    }

                    @include media("very-large")
                    {
                        left: 200px;
                    }

                    &::before
                    {
                        @include media("medium")
                        {
                            left: 89px;
                            top: -28px;
                        }

                        @include media("large")
                        {
                            left: 130px;
                        }
                    }
                }
            }

            &__headline
            {
                margin-bottom: 14px;
                color: $color--mine-shaft;
                font-weight: 700;
                font-size: 1.625rem;
                line-height: 1.625rem;
            }

            &__description
            {
                color: $color--mine-shaft;
                font-size: 1rem;
                line-height: 1.4rem;
            }
        }

        @keyframes rightRotateSmall
        {
            from
            {
                transform: translate(-50%, -50%) rotate(0deg);
            }
            to
            {
                transform: translate(-50%, -50%) rotate(360deg);
            }
        }

        @keyframes rightRotate
        {
            from
            {
                transform: rotate(0deg);
            }
            to
            {
                transform: rotate(360deg);
            }
        }

        @keyframes leftRotateSmall
        {
            from
            {
                transform: translate(-50%, -50%) rotate(360deg);
            }
            to
            {
                transform: translate(-50%, -50%) rotate(0deg);
            }
        }

        @keyframes leftRotate
        {
            from
            {
                transform: rotate(360deg);
            }
            to
            {
                transform: rotate(0deg);
            }
        }

        #internalCircle
        {
            animation: 32s rightRotateSmall infinite linear;

            @include media("very-small")
            {
                animation: 32s rightRotate infinite linear;
            }
        }

        #externalCircle
        {
            animation: 48s leftRotateSmall infinite linear;

            @include media("very-small")
            {
                animation: 32s leftRotate infinite linear;
            }
        }
    }

    &__areas
    {
        @extend %container;

        display: flex;
        flex-wrap: wrap;
        padding-right: 0;
        padding-left: 0;
        padding-bottom: 50px;

        @include media("medium")
        {
            padding-bottom: 144px;
        }

        @include media("very-large")
        {
            justify-content: flex-start;
        }

        .areas
        {
            &__item
            {
                box-sizing: border-box;
                width: 160px;
                height: 160px;
                padding: 24px;
                border: 1px solid $color--silver;
                margin-left: -1px;
                margin-top: -1px;
                transition: background 0.2s;

                @include media("small")
                {
                    width: 180px;
                    height: 180px;
                }

                @include media("medium")
                {
                    width: 230px;
                    height: 230px;
                    padding: 36px;
                }

                &:hover
                {
                    background-color: $color--science-blue;
                }

                &:hover .areas__icon
                {
                    filter: brightness(0) invert(1);
                }

                &:hover .areas__headline
                {
                    color: $color--white;
                }
            }

            &__icon
            {
                height: 50px;
                margin-bottom: 22px;
                object-position: left center;
                object-fit: scale-down;
                transition: filter 0.2s;

                @include media("medium")
                {
                    height: 69px;
                    margin-bottom: 32px;
                }
            }

            &__headline
            {
                color: $color--mine-shaft;
                transition: color 0.2s;
                font-weight: 700;
                font-size: 0.78rem;
                line-height: 0.78rem;
                word-wrap: break-word;

                @include media("medium")
                {
                    font-size: 1rem;
                    line-height: 1rem;
                }
            }

            &__break
            {
                @include media("very-large")
                {
                    flex-basis: 100%;
                }
            }
        }
    }

    &__contact
    {
        box-sizing: border-box;
        padding-right: 24px;
        padding-left: 24px;
        padding-top: 100px;
        padding-bottom: 125px;
        margin-bottom: 50px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("../../assets/graphics/images/stethoscope.jpg");

        @include media("medium")
        {
            width: 720px;
            padding-right: 80px;
            padding-left: 80px;
            padding-top: 80px;
            padding-bottom: 100px;
            margin-right: auto;
            margin-left: auto;
            margin-bottom: 120px;
        }

        @include media("large")
        {
            width: 960px;
            padding-right: 130px;
            padding-left: 130px;
        }

        @include media("very-large")
        {
            width: 100%;
            max-width: 1410px;
        }

        .contact
        {
            &__description
            {
                margin-bottom: 40px;
                color: $color--white;
                font-size: 1rem;
                line-height: 1.4rem;

                @include media("medium")
                {
                    margin-bottom: 20px;
                }
            }

            &__e-mail
            {
                margin-bottom: 40px;
                color: $color--white;
                font-weight: 700;
                font-size: 1rem;
                line-height: 1rem;

                @include media("medium")
                {
                    font-size: 1.75rem;
                    line-height: 1.75rem;
                }
            }

            &__logo
            {
                width: 200px;
                margin-left: 10px;

                @include media("medium")
                {
                    margin-left: 90px;
                }
            }
        }
    }

    &__map
    {
        position: relative;
        padding-top: 40px;
        padding-bottom: 150px;
        margin-bottom: 200px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("../../assets/graphics/images/background--gray.jpg");

        @include media("large")
        {
            padding-top: 90px;
            padding-bottom: 100px;
            margin-bottom: 120px;
        }

        @include media("very-large")
        {
            padding-bottom: 190px;
        }

        .map
        {
            &__container
            {
                padding-right: 24px;
                padding-left: 24px;

                @include media("medium")
                {
                    padding-right: 0;
                    padding-left: 0;
                    margin-right: auto;
                    margin-left: auto;
                    width: 720px;
                }

                @include media("large")
                {
                    width: 960px;
                }

                @include media("very-large")
                {
                    width: 1200px;
                }
            }

            &__group
            {
                @include media("large")
                {
                    max-width: 500px;
                    width: calc(100% - 649px);
                }
            }

            &__headline
            {
                margin-bottom: 40px;
                color: $color--mine-shaft;
                font-weight: 700;
                font-size: 2.5rem;
                line-height: 3rem;
            }

            &__description
            {
                margin-bottom: 20px;
                color: $color--mountain-mist;
                font-size: 1.125rem;
                line-height: 1.525rem;
            }

            &__poland
            {
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translate(-50%, 50%);
                width: 320px;

                @include media("large")
                {
                    top: 40px;
                    transform: translateX(-20%);
                    width: 649px;
                }

                @include media("very-large")
                {
                    transform: translateX(-10%);
                }

                #marker1
                {
                    left: 182.5px;
                    top: 225px;

                    @include media("large")
                    {
                        left: 365px;
                        top: 450px;
                    }

                    &::before
                    {
                        content: "Kraków\00a0I";
                    }
                }

                #marker2
                {
                    left: 197.5px;
                    top: 225px;

                    @include media("large")
                    {
                        left: 395px;
                        top: 450px;
                    }

                    &::before
                    {
                        content: "Kraków\00a0II";
                    }
                }

                #marker3
                {
                    left: 190px;
                    top: 265px;

                    @include media("large")
                    {
                        left: 380px;
                        top: 530px;
                    }

                    &::before
                    {
                        content: "Nowy\00a0Targ";
                    }
                }

                #marker4
                {
                    left: 180px;
                    top: 252.5px;

                    @include media("large")
                    {
                        left: 360px;
                        top: 505px;
                    }

                    &::before
                    {
                        content: "Wadowice";
                    }
                }

                #marker5
                {
                    left: 200px;
                    top: 237.5px;

                    @include media("large")
                    {
                        left: 400px;
                        top: 475px;
                    }

                    &::before
                    {
                        content: "Wieliczka";
                    }
                }

                #marker6
                {
                    left: 230px;
                    top: 180px;

                    @include media("large")
                    {
                        left: 460px;
                        top: 360px;
                    }

                    &::before
                    {
                        content: "Radom";
                    }
                }

                #marker7
                {
                    left: 165px;
                    top: 230px;

                    @include media("large")
                    {
                        left: 330px;
                        top: 460px;
                    }

                    &::before
                    {
                        content: "Oświęcim";
                    }
                }

                #marker8
                {
                    left: 167.5px;
                    top: 242.5px;

                    @include media("large")
                    {
                        left: 335px;
                        top: 485px;
                    }

                    &::before
                    {
                        content: "Grojec";
                    }
                }

                #marker9
                {
                    left: 160px;
                    top: 215px;

                    @include media("large")
                    {
                        left: 320px;
                        top: 430px;
                    }

                    &::before
                    {
                        content: "Katowice";
                    }
                }

                #marker10
                {
                    left: 212.5px;
                    top: 225px;

                    @include media("large")
                    {
                        left: 425px;
                        top: 450px;
                    }

                    &::before
                    {
                        content: "Kraków\00a0III";
                    }
                }

                #marker11
                {
                    left: 225px;
                    top: 230px;

                    @include media("large")
                    {
                        left: 450px;
                        top: 460px;
                    }

                    &::before
                    {
                        content: "Tarnów";
                    }
                }

                #marker12
                {
                    left: 167.5px;
                    top: 255px;

                    @include media("large")
                    {
                        left: 335px;
                        top: 510px;
                    }

                    &::before
                    {
                        content: "Wadowice";
                    }
                }
            }

            &__image
            {
                width: 100%;
            }

            &__marker
            {
                position: absolute;
                width: 9px;
                height: 9px;
                transition: all 0.4s;
                border-radius: 50%;
                background-color: $color--science-blue;

                @include media("large")
                {
                    width: 15px;
                    height: 15px;
                }

                &::before
                {
                    display: none;
                }

                @keyframes pulse
                {
                    0%
                    {
                        transform: scale(1);
                    }
                    50%
                    {
                        transform: scale(1.5);
                    }
                    100%
                    {
                        transform: scale(1);
                    }
                }

                @keyframes clearPulse
                {
                    0%
                    {
                        transform: scale(1);
                    }
                    100%
                    {
                        transform: scale(1);
                    }
                }

                &[data-active="false"]
                {
                    animation: pulse 2s infinite;
                }

                &[data-active="true"]
                {
                    animation: clearPulse 2s infinite;
                    z-index: 2;
                    width: 30px;
                    height: 30px;
                    background-position: center;
                    background-size: 80%;
                    background-repeat: no-repeat;
                    background-image: url("../../assets/graphics/icons/b--white.png");

                    @include media("large")
                    {
                        width: 56px;
                        height: 56px;
                    }

                    &::before
                    {
                        position: absolute;
                        left: 50%;
                        bottom: -16px;
                        transform: translateX(-50%);
                        display: block;
                        color: $color--black;
                        font-weight: 700;
                        font-size: 0.7rem;
                        line-height: 0.7rem;
                        text-transform: uppercase;

                        @include media("large")
                        {
                            bottom: -28px;
                            font-size: 1rem;
                            line-height: 1rem;
                        }
                    }
                }
            }
        }
    }
}

.footer
{
    @extend %container;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 70px;

    @include media("large")
    {
        padding-bottom: 50px;
    }

    &__establishments
    {
        color: $color--mine-shaft;
        font-size: 0.8rem;
        line-height: 1rem;

        @include media("medium")
        {
            font-size: 1rem;
            line-height: 1.4rem;
        }
    }

    &__line
    {
        width: calc(100% - 56px);
        height: 1px;
        margin-right: 16px;
        background-color: $color--mountain-mist;
    }

    &__logo
    {
        display: block;
        width: 40px;
    }

    &__clause
    {
        width: 100%;
        padding-top: 24px;
        padding-bottom: 24px;
        color: $color--mountain-mist;
        font-size: 0.8rem;
        line-height: 1rem;
        text-align: justify;

        @include media("medium")
        {
            width: calc(100% - 180px);
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &__copyrights
    {
        color: $color--mountain-mist;
        font-size: 1.125rem;
        line-height: 1.125rem;
    }
}