@import "assets/styles/mixins.scss";

%font--primary--regular
{
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
}

%font--secondary--medium
{
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
}

%font--secondary--bold
{
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
}

%container
{
    box-sizing: border-box;
    padding-right: 24px;
    padding-left: 24px;
}

@include media("very-small")
{
    %container
    {
        width: 320px;
        padding-right: 0;
        padding-left: 0;
        margin-right: auto;
        margin-left: auto;
    }
}

@include media("small")
{
    %container
    {
        width: 480px;
    }
}

@include media("medium")
{
    %container
    {
        width: 720px;
    }
}

@include media("large")
{
    %container
    {
        width: 960px;
    }
}

@include media("very-large")
{
    %container
    {
        width: 1200px;
    }
}