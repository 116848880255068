@import "assets/styles/placeholders.scss";

a
{
    display: block;
    color: inherit;
    text-decoration: none;
}

body
{
    margin: 0;
}

h1
{
    margin: 0;
    font-weight: inherit;
    font-size: inherit;
}

h2
{
    margin: 0;
    font-weight: inherit;
    font-size: inherit;
}

h3
{
    margin: 0;
    font-weight: inherit;
    font-size: inherit;
}

h4
{
    margin: 0;
    font-weight: inherit;
    font-size: inherit;
}

hr
{
    overflow: visible;
    border-width: medium;
    border-style: none;
    margin: 0;
    color: inherit;
}

html
{
    @extend %font--primary--regular;

    scroll-behavior: smooth;
    font-size: 16px;
    line-height: 1.6;
}

img
{
    display: block;
}

p
{
    margin: 0;
}

ul
{
    padding: 0;
    margin: 0;
    list-style-type: none;
}