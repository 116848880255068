$breakpoint--very-small: 360px;
$breakpoint--small: 600px;
$breakpoint--medium: 768px;
$breakpoint--large: 1024px;
$breakpoint--very-large: 1280px;

$color--white: #FFFFFF;
$color--trinidad: #F64D0B;
$color--science-blue: #0960E1;
$color--silver: #BDBBBB;
$color--mountain-mist: #8E8D90;
$color--mine-shaft: #393738;
$color--black: #000000;